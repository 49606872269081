import APIService from './APIService';

export default {
  getConciliacoes() {
    return APIService.apiCall().get('/conciliacoes/get-conciliacoes');
  },
  getConciliacao(id) {
    return APIService.apiCall().get(`/conciliacoes/get-conciliacao/${id}`);
  },
  deletar(id) {
    return APIService.apiCall().get(`/conciliacoes/deletar/${id}`);
  },
  executar(id) {
    return APIService.apiCall().get(`/conciliacoes/executar/${id}`);
  },
  duplicar(id) {
    return APIService.apiCall().get(`/conciliacoes/duplicar/${id}`);
  },
  validaHeader(id, postData) {
    return APIService.apiCall().post(
      `/conciliacoes/validar-header/${id}`,
      JSON.stringify(postData)
    );
  },
  salvar(id, postData) {
    return APIService.apiCall().post(
      `/conciliacoes/salvar/${id}`,
      JSON.stringify(postData)
    );
  },
  baixarArquivoModelo(tipoConciliacao) {
    return APIService.apiCall().get(
      `conciliacoes/download-arquivo-modelo/${tipoConciliacao}`,
      {
        responseType: 'blob',
        ...{
          timeout: 60 * 60 * 1000
        }
      }
    );
  },
  download(id, config = {}) {
    return APIService.apiCall().get(
      `/conciliacoes/baixar-arquivo-resultado/${id}`,
      { responseType: 'blob', ...config }
    );
  }
};
