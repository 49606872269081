<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      v-if="renderizacaoLiberada"
      v-model="tab"
      class="mx-auto"
      :title="title"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      :maxWidth="tipoConciliacao == 'EXPRESS' ? '700' : '870'"
      subtitle="Preencha as Informações para gerar sua conciliação."
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
      btnSaveCustomId="btn-save-conciliacao"
    >
      <conciliacao-express-wizard-tabs
        v-if="tipoConciliacao == 'EXPRESS'"
        ref="parametrosConciliacaoEXPRESS"
        :conciliacao="conciliacao"
        :tab="tab"
        @step-observer="updateTabStatus($event)"
        @update:tab="tab = $event"
      />
      <conciliacao-custom-wizard-tabs
        v-else-if="tipoConciliacao == 'CUSTOM'"
        ref="parametrosConciliacaoCUSTOM"
        :conciliacao="conciliacao"
        :tab="tab"
        @step-observer="updateTabStatus($event)"
        @update:tab="tab = $event"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import ConciliacoesService from '@/services/ConciliacoesService';
import wizardMixins from '@/mixins/wizardMixins';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins, tourMixins],
  components: {
    MaterialWizard: () => import('@/components/base/MaterialWizard.vue'),
    ConciliacaoExpressWizardTabs: () =>
      import(
        '@/components/power-ups/conciliacoes/ConciliacaoExpressWizardTabs.vue'
      ),
    ConciliacaoCustomWizardTabs: () =>
      import(
        '@/components/power-ups/conciliacoes/ConciliacaoCustomWizardTabs.vue'
      )
  },
  created() {
    this.tipoConciliacao = this.getTipoConciliacaoByRouter;
    if (this.$route.params.id) {
      this.getConciliacao(this.$route.params.id);
    }
    this.iniciarTourPagina();
  },
  data: () => ({
    tipoConciliacao: '',
    tabs: ['Início', 'Partida', 'Referência'],
    conciliacao: {},
    loading: false,
    arrTour: [
      {
        element: '#conciliacao-tipos-concilicao',
        intro:
          'Inicialmente você deve escolher qual o tipo da sua conciliação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#general-card-item-EXPRESS',
        intro:
          'O primeiro tipo é a <i>Conciliação Express</i>, que é simplificada e de rápida configuração',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#general-card-item-CUSTOM',
        intro:
          'Já o segundo tipo é a <i>Conciliação Custom</i>, que permite mais customizações e possui configurações de nível avançado.',
        scrollTo: 'tooltip',
        position: 'right'
      }
    ]
  }),
  computed: {
    title() {
      return this.$route.params.id
        ? `Editando Conciliação ${this.tipoConciliacao}`
        : `Adicionar Nova Conciliação ${this.tipoConciliacao}`;
    },
    renderizacaoLiberada() {
      if (!this.$route.params.id) return true;
      return this.$route.params.id && JSON.stringify(this.conciliacao) !== '{}'
        ? true
        : false;
    },
    getTipoConciliacaoByRouter() {
      let breadcrumb = this.$route.meta.breadcrumb;
      return breadcrumb
        ? breadcrumb[breadcrumb.length - 1]['name'].toUpperCase()
        : '';
    }
  },
  methods: {
    getConciliacao(id) {
      ConciliacoesService.getConciliacao(id)
        .then((response) => {
          this.conciliacao = response.data.data;
          this.tipoConciliacao = this.conciliacao.tipo_conciliacao;
          this.liberarEtapasWizzard();
        })
        .catch(() => {
          this.$router.replace({
            name: routes.state.appRoutes['CONCILIACOES_HISTORICO']
          });
          this.$toast.error('Erro ao recuperar conciliação.', '', {
            position: 'topRight'
          });
        });
    },
    save() {
      const id = this.$route.params.id ? this.$route.params.id : null;
      const data = this.getDadosConciliacao();

      this.loading = true;

      ConciliacoesService.salvar(id, data)
        .then(() => {
          this.$router.replace({
            name: routes.state.appRoutes['CONCILIACOES_HISTORICO']
          });
          this.$toast.success('Conciliação salva com sucesso.', '', {
            position: 'topRight'
          });
        })
        .catch((err) => {
          console.error('Erro: ', err);
          this.$toast.error('Erro ao salvar conciliação.', '', {
            position: 'topRight'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDadosConciliacao() {
      let conciliacao =
        this.$refs[`parametrosConciliacao${this.tipoConciliacao}`];
      let partida = conciliacao.partidaReferencia.partida;
      let referencia = conciliacao.partidaReferencia.referencia;
      let flexibilidade = '';
      const rodadas = conciliacao.rodadas.map((rodada) => rodada.valores);
      const atributos = Array.from(new Set([].concat.apply([], rodadas)));

      const regra =
        this.tipoConciliacao == 'EXPRESS' ? 'DATA_CONEXAO_ORDENACAO' : '';
      const vars =
        this.tipoConciliacao == 'EXPRESS'
          ? {
              DATA_CONEXAO_ORDENACAO: {
                partida: 'Data',
                referencia: 'DATA_CONEXAO'
              }
            }
          : {};

      let prioridadeOrdenacao =
        this.tipoConciliacao == 'EXPRESS'
          ? [...conciliacao.prioridades, 'DATA_CONEXAO_ORDENACAO']
          : conciliacao.prioridades;
      prioridadeOrdenacao = prioridadeOrdenacao.filter(
        (atrib) =>
          ![
            'ODI',
            'TI',
            'UAR',
            'IDUC',
            'Inventario',
            'Obra',
            'Material',
            'Generico_1',
            'Generico_2',
            'Generico_3',
            'Periodo'
          ].includes(atrib)
      ); // Exceções de atributos na prioridadeOrdenacao

      if (conciliacao.flexibilidade === 'Flexível') {
        flexibilidade = 'FLEXIVEL';
      } else if (conciliacao.flexibilidade === 'Rígida') {
        flexibilidade = 'RIGIDA';
      } else {
        flexibilidade = 'PARCIAL';
      }

      return {
        nome: conciliacao.titulo,
        tuc_selecionada: conciliacao.tucSelecionada,
        tipo_conciliacao: conciliacao.tipoConciliacao,
        flexibilidade: flexibilidade,
        numero_rodadas: conciliacao.rodadas.length,

        origem_dados_contabil: partida.origem,
        relatorio_contabil_id:
          partida.origem == 'RELATORIO' ? partida.relatorioId : '',
        arquivo_contabil_id:
          partida.origem == 'ARQUIVO' ? partida.arquivoId : '',
        arquivo_contabil_delimitador: partida.delimitador,
        arquivo_contabil_involucro: partida.involucro,
        arquivo_contabil_escape: partida.escape,
        mapeamento_contabil: partida.mapeamento ? partida.mapeamento : {},

        origem_dados_fisico: referencia.origem,
        relatorio_fisico_id:
          referencia.origem == 'RELATORIO' ? referencia.relatorioId : '',
        arquivo_fisico_id:
          referencia.origem == 'ARQUIVO' ? referencia.arquivoId : '',
        arquivo_fisico_delimitador: referencia.delimitador,
        arquivo_fisico_involucro: referencia.involucro,
        arquivo_fisico_escape: referencia.escape,
        mapeamento_fisico: referencia.mapeamento ? referencia.mapeamento : {},

        parametros: {
          tipo: this.getParametroTipo(atributos),
          vars: vars,
          rodadas: rodadas,
          regra: regra,
          prioridadeBusca: atributos,
          prioridadeOrdenacao: prioridadeOrdenacao,
          flexibilidadeConciliacao: this.getParametrosFlexibilidade(
            conciliacao.flexibilidade
          ),
          vazio: conciliacao.atributosCoringa ? 'DIFNAN' : 'TODOS'
        }
      };
    },
    getParametroTipo(atributos) {
      let tipo = { float: ['QTD'] };

      if (this.tipoConciliacao == 'EXPRESS') {
        tipo = { float: ['QTD'], data: ['Data', 'DATA_CONEXAO'] };
      }

      const tiposList = {
        ODI: 'str',
        TUC: 'float',
        A1: 'float',
        A2: 'float',
        A3: 'float',
        A4: 'float',
        A5: 'float',
        A6: 'float',
        TI: 'float',
        Data: 'data',
        DATA_CONEXAO: 'data'
      };

      atributos.forEach((atrib) => {
        const key = tiposList[atrib] ? tiposList[atrib] : 'str';
        tipo[key] = tipo[key] !== undefined ? [...tipo[key], atrib] : [atrib];
      });

      return tipo;
    },
    getParametrosFlexibilidade(flexibilidade) {
      const flexibilidades = {
        Flexível: { a: 'n', b: 'n' },
        Rígida: { a: '1', b: '1' },
        Parcial: { a: '1', b: 'n' }
      };

      return flexibilidades[flexibilidade];
    },
    iniciarTourPagina() {
      if (this.tourFuncCalled) return;
      this.iniciarTour(this.arrTour);
    }
  }
};
</script>
